// Imports
import { Card, Row, Col, Image, Container, Badge } from 'react-bootstrap';
import React from "react";
import axios from "axios";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import egInstaLogo from '../../assets/img/egInstaLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';

// Build EG Instagram URL
const fields = 'id,caption,media_url,permalink,thumbnail_url,media_type,like_count,comments_count';
const accessToken = 'IGQVJVVXRqNi0zbG5WRWttVjZAaNU5PXzJpMm9rb040MEFVMGp5QnQxaDQzTDhBOWtsYTNDSlBxbHh0Y1B6MG9rdkVEbHBGMVdLc0pjRGxSeHozT01SV1FsZA2dHSF9NUkdLR2VHWUluR2c1YU1wWHI0bAZDZD';
const baseURL = `https://graph.instagram.com/me/media?fields=${ fields }&access_token=${ accessToken }`;

const ImagesWidget = () => {

  const [images, setImages] = React.useState(null);   
  const attr = {
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: false
  };

  React.useEffect(() => {

    let shouldCancel = false;

    const call = async () => {

      let response = await axios.get( baseURL );
      let images = response.data.data;
          
      if (!shouldCancel && images && images.length > 0) {

        setImages(
          images.map(url =>
            ({
              data: `${ JSON.stringify( url ) }`,
              original: ( url.media_type === 'VIDEO') ? `${url.thumbnail_url}` : `${url.media_url}`,
              thumbnail: ( url.media_type === 'VIDEO') ? `${url.thumbnail_url}` : `${url.media_url}`        
            })
          )
        )        
      }
    };

    call();

    return () => (shouldCancel = true);

  }, []);

  function _onSlide(index) {
    console.log(index)
    console.log(JSON.parse(images[ index ].data));
  }

  return images ? 
    <Row className="g-0 p-5 pb-0 pt-4">        
      <Col>
        <Card>        
          <Card.Body>
            <Card.Text className="cardTitle text-center">Follow Us On Instagram</Card.Text>
            {}
          </Card.Body>
        </Card>
        <Container style={{ maxWidth: '43rem' }}>
          <Row className="text-left ps-4">
            <h5>
              <Badge bg="secondary"><Image src={ egInstaLogo } width={ 50 } /></Badge> everythinggoesaz
            </h5>                    
          </Row>          
          <ImageGallery 
            onSlide={ _onSlide.bind(this) }
            items={ images }      
            showFullscreenButton={ attr.showFullscreenButton }
            showPlayButton={ attr.showPlayButton }
            thumbnailPosition='right' />
          <Row className="text-left ps-4 pt-2">
            <h2 className="mb-0">
              <Badge bg="secondary" className="pb-0">
                <FontAwesomeIcon icon={ faHeart } style={{ color: '#d50000' }} />   
              </Badge>
              <Badge bg="secondary" className="pb-0 ps-0">
                <FontAwesomeIcon className="instaComment" icon={ faComment } style={{ color: 'grey' }} />   
              </Badge>              
            </h2>           
            <h6 className="ps-4">
              <span style={{ fontWeight: 'bold' }}>&nbsp; everythinggoesaz</span> Test Captions
              <div className="ps-4 pt-3">
                <small>
                  <a href="https://www.instagram.com/everythinggoesaz/" target="_blank" style={{ textDecoration: 'none', color: 'gray' }}>
                    Everything Goes (@everythinggoesaz) • Instagram photos and videos
                  </a>
                </small>
              </div>
            </h6>         
          </Row>          
        </Container>
      </Col>

    </Row>
  : null;
};

export default ImagesWidget;

// Imports
// import { Card, Row, Col, Image, Container } from 'react-bootstrap';
// import axios from "axios";
// import React from "react";
// import ImageGallery from 'react-image-gallery';

// const fields = 'id,caption,media_url,permalink,thumbnail_url,media_type';
// const accessToken = 'IGQVJVVXRqNi0zbG5WRWttVjZAaNU5PXzJpMm9rb040MEFVMGp5QnQxaDQzTDhBOWtsYTNDSlBxbHh0Y1B6MG9rdkVEbHBGMVdLc0pjRGxSeHozT01SV1FsZA2dHSF9NUkdLR2VHWUluR2c1YU1wWHI0bAZDZD';

// const baseURL = `https://graph.instagram.com/me/media?fields=${ fields }&access_token=${ accessToken }`;

// function setupImageGallery( data ) {

//   console.log( data )

//   const images = data.map(( image ) => {

//     return {
//       original: image.media_url,
//       thumbnail: image.thumbnail_url,
//       originalHeight: '200px',
//       thumbnailPosition: 'right'
//     };
//   });

//   console.log( images );

//   return images;
// }

// // Export
// function Gallery() {

//   /**
//    * Get Images From EG Instagram
//    */
//   const [post, setPost] = React.useState(null);

//   React.useEffect(() => {
//     axios.get(baseURL).then((response) => {
//       setPost(response.data.data);
//     });
//   }, []);

//   // Set images if found
//   if (!post) return null;

//   /**
//    * Build Images Object 
//    */
//   const images = setupImageGallery( post );
  
//   return (
    
//     <Row className="g-0 p-5 pb-0 pt-4">        
//       <Col>
//         <Card>        
//           <Card.Body>
//             <Card.Text className="cardTitle text-center">Everything Goes Instagram</Card.Text>
//           </Card.Body>
//         </Card>
//       </Col>

//       <Container>
//         <ImageGallery 
//           items={ images } 

//         />
//       </Container>
        

//       {/* <Row xs={1} md={4} className="g-4">
//         {Array.from({ length: post.length }).map((_, idx) => (
//           <Col>
//             <Card border="default">
//               <a href={ post[idx].permalink } target="_blank">
//                 <Card.Img variant="top" className="pt-2 img-thumbnail" 
//                   src={ ( post[idx].media_type === 'VIDEO' ) ? post[idx].thumbnail_url : post[idx].media_url } 
//                   style={{ alignSelf: 'center', maxWidth: '30rem' }}  
//                 />
//               </a>
//               <Card.Body>
//                 <Card.Text>{ post[idx].caption }</Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>  */}
      
//     </Row>    
//   );
// }

// export default Gallery;