// Imports
import { Card, Row, Col, ResponsiveEmbed } from 'react-bootstrap';
import { PodcastIcon } from '@fortawesome/fontawesome-free/';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

// Import Images
import ImagesWidget from '../Gallery/widget.js';

// Export
function SocialMedia() {

  var names = [ 
    'Instagram',
    // 'Facebook',
    'Podbean Podcast'
  ];

  var icons = [
    <FontAwesomeIcon icon={ faInstagram } className="fa-stack-1x" />,
    // <FontAwesomeIcon icon={ faFacebook } className="fa-stack-1x" />,
    <FontAwesomeIcon icon={ faPodcast } className="fa-stack-1x" />
  ]

  var links = [
    'https://www.instagram.com/everythinggoesaz/',
    'https://www.facebook.com/everythinggoesaz/',
    'https://itdoesntjustappear.podbean.com/'
  ]

  function getEmbedMedia( type ) {

    switch( type ) {

      case 'Instagram':

        return (
          <iframe
            src="https://www.instagram.com/everythinggoesaz/embed"
            width='100%'
            minWidth='min(100%, 430px)'
            height='580em'
            frameBorder={0}
            scrolling="no"
            allowTransparency="true"
          />
          // <ImagesWidget />
        )

      break;

      case 'Facebook':

      break;

      case 'Podbean Podcast':

        return (
          <Card.Text>
            <ResponsiveEmbed aspectRatio="16x9">
              <embed title="It Doesn't Just Appear" allowtransparency="true" scrolling="no" data-name="pb-iframe-player" style={{ height: '30rem', width: '100%', border: 'none', minWidth: 'min(100%, 430px)' }} type="image/svg+xml" allowfullscreen="" src="https://www.podbean.com/player-v2/?i=5htff-51e4a3-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=1&btn-skin=00ccff&font-color=auto&order=episodic&limit=10&filter=all&ss=a713390a017602015775e868a2cf26b0&btn-skin=7&size=315" />
            </ResponsiveEmbed>                
          </Card.Text>
        );

      break;
    }
  }

  return (
    <div className="pb-5">     
      <Row xs={1} md={2} className="pt-3">
        {Array.from({ length: names.length }).map((_, idx) => (
          <Col>
            <Card>    
              <Card.Body>
                <Card.Text className="cardIcon">
                  <span className="fa-stack" style={{ fontSize: '40px' }}>
                    <FontAwesomeIcon icon={ faCircle } className="fa-stack-2x" />
                    <FontAwesomeIcon icon={ faCircle } className="fa-stack-2x" style={{ color: 'rgb(248 249 250)', fontSize: '43px', marginRight: '-3.5px' }} />
                    { icons[ idx ] }  
                  </span>    
                </Card.Text>                          
                <Card.Title className="mt-3 mb-3 cardTitle text-center" style={{ fontSize: '22px', fontWeight: 'bold' }}>
                  { names[ idx ] }
                </Card.Title>
                <Card.Text className="cardIcon pt-3 pb-3">
                  <FontAwesomeIcon icon={ faChevronDown } />
                </Card.Text>

                {/* Draw Embedded Social Media Card */}
                { getEmbedMedia( names[idx] )} 
                              
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>      
    </div>
  );
}

export default SocialMedia;