// Imports
import { Card, Row, Col, Image, Container, Badge } from 'react-bootstrap';
import React from "react";
import axios from "axios";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import egInstaLogo from '../../assets/img/egInstaLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';

// Build EG Instagram URL
const fields = 'id,caption,media_url,permalink,thumbnail_url,media_type,like_count,comments_count';
const accessToken = 'IGQVJVVXRqNi0zbG5WRWttVjZAaNU5PXzJpMm9rb040MEFVMGp5QnQxaDQzTDhBOWtsYTNDSlBxbHh0Y1B6MG9rdkVEbHBGMVdLc0pjRGxSeHozT01SV1FsZA2dHSF9NUkdLR2VHWUluR2c1YU1wWHI0bAZDZD';
const baseURL = `https://graph.instagram.com/me/media?fields=${ fields }&access_token=${ accessToken }`;

const ImagesGallery = () => {

  const [images, setImages] = React.useState(null);   
  const attr = {
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: false
  };

  React.useEffect(() => {

    let shouldCancel = false;

    const call = async () => {

      let response = await axios.get( baseURL );
      let images = response.data.data;
          
      if (!shouldCancel && images && images.length > 0) {

        setImages(
          images.map(url =>
            ({
              data: `${ JSON.stringify( url ) }`,
              original: ( url.media_type === 'VIDEO') ? `${url.thumbnail_url}` : `${url.media_url}`,
              thumbnail: ( url.media_type === 'VIDEO') ? `${url.thumbnail_url}` : `${url.media_url}`        
            })
          )
        )        
      }
    };

    call();

    return () => (shouldCancel = true);

  }, []);

  function _onSlide(index) {
    console.log(index)
    console.log(JSON.parse(images[ index ].data));
  }

  /**
   * getImage
   * 
   * Returns JSX of image component with link to instagram image
   * 
   * @param {numeric} idx 
   * @param {numeric} col 
   * @returns 
   */
  function getImage( idx, col ) {

    var thisObj = JSON.parse(images[idx].data);
    var thumnailURL = thisObj.media_type === 'VIDEO' ? thisObj.thumbnail_url: thisObj.media_url;
    var image = 
    <a href={ thisObj.permalink } target="_blank" id={ thisObj.id }>
      <Card>
        <Card.Img className="ms-auto me-auto img-thumbnail img-fluid" variant="top" src={ thumnailURL } />
      </Card> 
    </a>

    if( col === 1 && ( idx >= 0 && idx < 6 ) ) {

      return image;
    }

    if( col === 2 && ( idx >= 6 && idx < 12 ) ) {

      return image;
    }

    if( col === 3 && ( idx >= 12 && idx < 18 ) ) {

      return image;
    }

    if( col === 4 && ( idx >= 18 && idx < 24 ) ) {

      return image;
    }
  }

  return images ? 
    <div id="galleryDiv">
      <Row className="pt-3">        
        {/*<Row className="g-0 p-5 pb-0 pt-4">        
        <Col>
          <Card>        
            <Card.Body>
              <Card.Text className="cardTitle text-center">Follow Us On Instagram</Card.Text>
              {}
            </Card.Body>
          </Card>
          <Container>
            <Row className="text-left ps-4">
              <h5>
                <Badge bg="secondary"><Image src={ egInstaLogo } width={ 50 } /></Badge> everythinggoesaz
              </h5>                    
            </Row>          
            <Row xs={1} lg={3} md={2} className="g-4">
              {Array.from({ length: 24 }).map((_, idx) => (
                <Col>
                  <Card style={{ height: '30em' }}>
                    <Card.Img className="ms-auto me-auto img-thumbnail img-fluid" variant="top" style={{ maxWidth: '20em' }} src={ JSON.parse(images[idx].data).media_type === 'VIDEO' ? JSON.parse(images[idx].data).thumbnail_url: JSON.parse(images[idx].data).media_url } />
                    <Card.Body className="text-left">
                      <Card.Title>
                      <h2 className="mb-0">
                        <Badge bg="secondary" className="pb-0">
                          <FontAwesomeIcon icon={ faHeart } style={{ color: '#d50000' }} />   
                        </Badge>
                        <Badge bg="secondary" className="pb-0 ps-0">
                          <FontAwesomeIcon className="instaComment" icon={ faComment } style={{ color: 'grey' }} />   
                        </Badge>              
                      </h2>
                      </Card.Title>
                      <Card.Text>
                        <h6 className="ps-3">
                          <span style={{ fontWeight: 'bold' }}>everythinggoesaz</span> {JSON.parse(images[idx].data).caption}
                        </h6>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

          </Container>
        </Col> */}
        <Card>        
          <Card.Body>
            <Card.Text className="cardTitle text-center pt-3">Gallery</Card.Text>          
          </Card.Body>
        </Card>
        {/* <Row className="text-left ps-4">
          <h5>
            <Badge bg="secondary"><Image src={ egInstaLogo } width={ 50 } /></Badge> everythinggoesaz
          </h5>                    
        </Row> */}
        <Row xs={1} sm={1} lg={4} md={2} className="gx-0 ps-3 pe-3" id="egGalleryCols">
          <Col className="p-1">
            {Array.from({ length: 24 }).map((_, idx) => getImage( idx, 1 ))}
          </Col>
          <Col className="p-1">
            {Array.from({ length: 24 }).map((_, idx) => getImage( idx, 2 ))}
          </Col>
          <Col className="p-1">
            {Array.from({ length: 24 }).map((_, idx) => getImage( idx, 3 ))}
          </Col> 
          <Col className="p-1">
            {Array.from({ length: 24 }).map((_, idx) => getImage( idx, 4 ))}
          </Col>       
        </Row>

      </Row>
    </div>
  : null;
};

export default ImagesGallery;
