// Imports
import { Row, Card, Col, Form, Button } from 'react-bootstrap';
import SocialMedia from '../SocialMedia';
import Map from '../Map';
import ContactForm from './form';

function Contact() {
  return( 
    
    <div id="contactDiv">

      <Row className="pt-3">
        <Card>
          <Card.Body>
            <Card.Text className="cardTitle text-center pt-3">
              Get In Touch With Us!              
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      {/* Social Media Links */}
      <SocialMedia className="" />

      {/* Contact Form 
      <Row xs={1} md={2} className="">        
        
        <Col>
          <Card>        
            <Card.Body>              
              <Card.Text className="cardText">
                If your looking for a specific peice, give us a call or send us an email to see what we have in store!
                <br></br>
                <br></br>
                11649 N. Cave Creek Rd.
                <br></br>
                Phoenix, AZ 85020
                <br></br>
                Call Us At (602) 331-3383
              </Card.Text>              
              <Card.Text className="cardText">Be sure to follow Everything Goes on social media for new peices and daily updates!</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <ContactForm />

      </Row>  */}      

    </div>
  );
}

export default Contact;