// Imports
import logo from './logo.svg';
import './App.css';
import { Container } from 'react-bootstrap';

// Site Components
import PodcastBanner from './components/PodcastBanner/index.js';
import Jumbotron from './components/Jumbotron/index.js';
import QuickBio from './components/QuickBio/index.js';
import About from './components/About/index.js';
import Gallery from './components/Gallery/index.js';
import Podcast from './components/Podcast/index.js';
import Contact from './components/Contact/index.js';
import Footer from './components/Footer';
import Header from './components/Header';
import Copyright from './components/Copyright';

// Main App
function App() {

  /**
   * set highlight on menu on scroll
   */
  function getScroll() {

    document.addEventListener("DOMContentLoaded", function(event) { 

      window.addEventListener("scroll", function(event) {

        // let aboutDiv = window.scrollY + document.querySelector('#aboutDiv').getBoundingClientRect().top;
        // var galleryDiv = this.scrollY + document.querySelector('#galleryDiv').getBoundingClientRect().top;
        // var podcastDiv = this.scrollY + document.querySelector('#podcastDiv').getBoundingClientRect().top;
        // var contactDiv = this.scrollY + document.querySelector('#contactDiv').getBoundingClientRect().top;
      
        // Reset highlighting
        document.querySelector('#aboutNavLink').classList.remove('active');
        document.querySelector('#galleryNavLink').classList.remove('active');
        document.querySelector('#podcastNavLink').classList.remove('active');
        document.querySelector('#contactNavLink').classList.remove('active');

        // if( this.scrollY < document.querySelector('#galleryDiv').offsetTop ) {
  
        //   document.querySelector('#aboutNavLink').classList.add('active');
        // }
        
        // console.log(this.scrollY ); 
      });
    });  
  }

  return (
    <div className="App">
      <header className="App-header">        
        <Jumbotron /> 
        <Header />
      </header>
      <body>        
        <Container fluid="lg">
          <About />
          <Gallery />          
          <Podcast />        
          <Contact />
        </Container>                 
      </body>
      <footer>
        <Container fluid="lg">
          <Footer />
        </Container>
        <Copyright />
      </footer>

      {/* Highlight Navbar Links On Scroll */}
      { getScroll() }

    </div>
  );
}

export default App;
