
import './index.css';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import backgroundImage from '../../assets/img/Everything-Goes-Background.png';

function Jumbotron() {
  return (
    <div className="bg-dark text-white" style={{ maxHeight: '100vh', overflow: 'hidden', zIndex: 9999999, position: 'relative' }}>
      <Card.Img src={ backgroundImage } alt="Card image" />
      <Card.ImgOverlay>
        <Card.Text>
          <p className="jumbotronTitle">Everything Goes</p>
          <p className="jumbotronText">ONE OF A KINDS AND HARD TO FINDS</p>
        </Card.Text>        
      </Card.ImgOverlay>
    </div>
  );
}

export default Jumbotron;
