// Imports
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Map from '../Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';

export default function Copyright() {
  return (    
    <Row className="m-0" style={{ backgroundColor: '#272a2b', color: '#868e96', fontSize: '.8rem' }}>
      <Col lg='6' md='6' sm='12'>
        <div className='p-3' id='copyRightText'>
          Copyright &copy; {new Date().getFullYear()}{' '}
          Everything Goes AZ | All Rights Reserved
        </div>
      </Col>
      <Col lg='6' md='6' sm='12' id='copyRightIcons' className="">
        <div className="p-3">
          
          {/* Instagram */}
          <a className="socialIconFooter" href="https://www.instagram.com/everythinggoesaz/" target="_blank"> 
            <FontAwesomeIcon icon={ faInstagram } />
          </a> 

          {/* Facebook */}
          <a className="socialIconFooter" href="https://www.instagram.com/everythinggoesaz/" target="_blank"> 
            <FontAwesomeIcon icon={ faFacebook } />
          </a> 

          {/* Podbean */}
          <a className="socialIconFooter" href="https://itdoesntjustappear.podbean.com/" target="_blank"> 
            <FontAwesomeIcon icon={ faPodcast } />
          </a> 
          
        </div>          
      </Col>
    </Row>
  );
}