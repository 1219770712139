// Imports
import { Card, Row, Col } from 'react-bootstrap';

// Import Images
import quickbioImg from '../../assets/img/QuickBio.png';

// One Stop Shop
import QuickBio from '../QuickBio';

// Export
function About() {

  return (
    <div id="aboutDiv">           
      <Row className="pt-3">
        <Card>
          <Card.Body>
            <Card.Text className="cardTitle text-center pt-3">About <span className="d-none d-md-inline-block">Everything Goes</span></Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row xs={1} md={2} className="">        
        <Col>
          <Card>        
            <Card.Body>
              <Card.Text className="cardText">
                One Of A Kinds and Hard To Find antiques, vintage, shabby chic, industrial and many other styles of home decor and furniture with new items added daily!
              </Card.Text>
              <Card.Text className="cardText">
                Everything Goes is a unique, and one of a kind store offering home furnishing with over 20 vendors, each with their own design style making Everything Goes a unique shopping experience!
              </Card.Text>
              <Card.Text className="cardText">
                Since 1995 Everything Goes has been providing customers with unique items, great prices and great customer service. With over 4,000 square feet of shopping space, the store offers home furnishings, antiques, collectibles, garden furniture/décor, jewelry, One Of A Kinds and Hard To Find items. The store has over 20 vendors each with their own design style making Everything Goes a unique shopping experience.
              </Card.Text>                            
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Img variant="top" src={ quickbioImg } className="everythingGrowsImg pt-2 img-thumbnail" style={{ alignSelf: 'center' }, { maxWidth: '30rem' }} />        
            </Card.Body>
          </Card>
        </Col>
      </Row>   
      <Row className="">        
        <Col>
          <Card>        
            <Card.Body>
              <Card.Text className="cardText">
                You will find the staff to be friendly and helpful with your shopping experience. Each staff member has a passion for vintage design and they are junkers as well.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>        
      </Row>
      
    </div>
  );
}

export default About;