// Imports
import { Card, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEtsy, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faDownload, faPodcast } from '@fortawesome/free-solid-svg-icons';

// Import Images
import wendyPodcast from '../../assets/img/wendyPodcast.jpeg';
import hawkPodcast from '../../assets/img/hawkPodcast.jpeg';
import egPodcast from '../../assets/img/egPodcast.jpeg';
import junkInTheTrunkPodcast from '../../assets/img/junkInTheTrunkPodcast.jpeg';
import junkInTheTrunk2Podcast from '../../assets/img/junkInTheTrunk2Podcast.jpeg';
import porterPodcast from '../../assets/img/porterPodcast.jpeg';
import retroRemoPodcast from '../../assets/img/retroRemoPodcast.jpeg';
import rustyPodcast from '../../assets/img/rustyPodcast2.jpeg';
import sandraPodcast from '../../assets/img/sandraPodcast.jpeg';
import taffeePodcast from '../../assets/img/taffeePodcast.jpeg';
import theVinylPodcast from '../../assets/img/theVinylDudePodcast2.jpeg';
import itDoesntJustAppear from '../../assets/img/itDoesntJustAppear.jpeg';

// One Stop Shop
import QuickBio from '../QuickBio';

// Export
function Podcast() {

  // Episodes
  const episodesHTML = [];
  const episodes = [    
    {
      title: 'Episode 11 - Rusty Saturday',
      subTitle: 'Riding Along with Rusty Saturday',
      text: 'Ride along the road of success with Rusty Saturday! Known for their ability to pick and recreate items to make others say, "how did they do that" is just a little about what they are about!',      
      podbean: 'https://itdoesntjustappear.podbean.com/e/riding-along-with-rusty-saturday/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/cwbvty/rusty_saturday.mp3',
      image: rustyPodcast
    },
    {
      title: 'Episode 10 - Sandra',
      text: 'In this exciting new episode I get to meet up with an old friend who now lives in the sunny state of California. Sandra and I talk about the interesting and beautiful pieces she brings to my shop. We get into all of our old stories which brings back some funny memories and we get to share them with you! Sandra tells us all where she goes to find these pieces and her own sense of style. She even tells us how she decorates and what she’s working on now. Tune in to hear all about these amazing stories.',    
      podbean: 'https://itdoesntjustappear.podbean.com/e/sandra-1574037799/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/zx2v3c/Sandra_2.mp3',
      image: sandraPodcast
    },
    {
      title: 'Episode 9 - Remo',
      subTitle: 'Retro Remo',
      text: 'In this episode we get to dive deep into the world of mid-century items with my friend Remo, talking about his unique & groovy finds! Remo has a love for pretty much anything that\'s mid-century especially sculptures and unique art. We get into various pieces he has collected and sold throughout the years. Tune in to hear all about these beautiful mid-century pieces and his outstanding eye for art!',      
      podbean: 'https://itdoesntjustappear.podbean.com/e/remos-lost-treasures/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/6i5vac/Remo_2.mp3',
      image: retroRemoPodcast
    },
    {
      title: 'Episode 8 - Nick',
      subTitle: 'The Vynl Record Dude',
      text: 'This week I got to see my friend, Nick, at his warehouse where he keeps his outstanding vinyl collection. Nick is one of my vendors at Everything Goes and has taught me a lot about these fascinating pieces of the music industry. We talk about how he finds these amazing treasures, how much they\'re worth, the different album’s and their popularity, and how you can tell a good record from a bad one. Nick’s nicknamed “The Vinyl Record Dude” because of his long held passion towards vinyl. Listen in to hear these amazing stories about vinyl that I had the pleasure to learn more about!',      
      podbean: 'https://itdoesntjustappear.podbean.com/e/the-vinyl-dude/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/ibtm5n/Nick_The_Vinyl_Record_Dude_.mp3',
      image: theVinylPodcast
    },
    {
      title: 'Episode 7 - My Shop and Me',
      subTitle: 'Everything Goes AZ',
      text: 'In this episode I talk about Everything Goes, and how I got started!',    
      instagram: 'https://www.instagram.com/everythinggoesaz/',
      podbean: 'https://itdoesntjustappear.podbean.com/e/my-shop-me/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/agr46h/MyShopMe.mp3',
      image: egPodcast
    },
    {
      title: 'Episode 6 - If Junk Could Only Talk Part 2',
      subTitle: 'Junk In The Trunk',
      text: 'Continuing our episode, "If Junk Could Only Talk" we interview Junk in the Trunk Vintage Market vendors, and the founders of Junk in the Trunk Coley, Lindsey, and Kevin. Tune in to hear all about how they started the Vintage Market and where their journey is taking them!',    
      instagram: 'https://www.instagram.com/junkinthetrunkvintagemarket/',
      podbean: 'https://itdoesntjustappear.podbean.com/e/part-2-if-junk-could-only-talk-coley-lindsey/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/w8ra8y/JITT_coley_lindsey.mp3',
      image: junkInTheTrunk2Podcast
    },
    {
      title: 'Episode 5 - If Junk Could Only Talk Part 1',
      subTitle: 'Junk In The Trunk',
      text: 'Junk In The Trunk is a large vintage market where thousands of shoppers are welcomed to shop vintage/antique and hand crafted pieces. Junk In The Trunk market started 8 years ago in the back yard of a few friends and has evolved into a great and successful market! Each year I\'ve been a vendor up until this year, rather than having my own space decided to have fun getting out and interviewing all of the markets amazing vendors. We met so many different people and saw so many amazing rustic, and vintage pieces. Join us in this weeks episode to discover all the uncovered treasures and the magnificent stories behind them!',
      instagram: 'https://www.instagram.com/junkinthetrunkvintagemarket/',
      podbean: 'https://itdoesntjustappear.podbean.com/e/if-junk-could-only-talk-part-1-junk-in-the-trunk-market/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/j7n5gg/Junk_in_the_Trunk-FirstPart.mp3',
      image: junkInTheTrunkPodcast
    },
    {
      title: 'Episode 4 - Thomas Porter',
      subTitle: 'Porter barn Wood',
      text: 'All of us "pickers" love to pick! Everyone of us have a passion for something unique which draws us to these incredible pieces! Thomas Porter has as love for many things, but his first love, (next to his family & bluegrass music) is old....really old barn wood! As the owner of Porter Barn Wood, Thomas is therefore able to turn his creative ideas into amazing furniture, flooring, and beautiful barn wood doors which is only just a small part of his business. In this podcast, Thomas talks to us about his business and how he finds the perfect barn wood to make his creations appear!',
      instagram: 'https://www.instagram.com/porterbarnwood/',
      podbean: 'https://itdoesntjustappear.podbean.com/e/wood-picker-thomas-porter/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/udff3r/Thomas.mp3',
      image: porterPodcast
    },
    {
      title: 'Episode 3 - Taffee',
      text: 'Connecting via social media, Taffee has become one of my pickers, and a great friend in the process! Taffee travels throughout the Midwest on a journey to find the hidden treasures left behind, all while building relationships with people and collectors alike! Within days, this picker can load a rental truck filled with fabulous finds, and travel back to Queen Creek AZ where she shares her finds with her local shop owners, vendors and personal relationships.',
      podbean: 'https://itdoesntjustappear.podbean.com/e/a-girl-and-a-big-truck/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/hhn9ar/Taffy_Tr1.mp3',
      image: taffeePodcast
    },
    {
      title: 'Episode 2 - Hawk Salvage',
      subTitle: 'Hawk',
      text: 'Phoenix Fire Fighter Hawk, who has a passion for not only helping others but finding unique & off the wall collectables! With only a few years left in the department Hawk is getting ready for retirement and to become a full time picker as well as an antique shop owner, Hawk Salvage! Hawk loves to travel across the US in his truck hauling his large trailer and shaking hands making new friends out on his travels. Hawks stories and how he comes across treasures are worth listening to!',
      etsy: 'https://www.etsy.com/se-en/people/hawksalvage',
      instagram: 'https://www.instagram.com/hawksalvage/',
      podbean: 'https://itdoesntjustappear.podbean.com/e/fire-fighter-picker/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/hmux8p/HawkTr1.mp3',
      image: hawkPodcast
    },
    {
      title: 'Episode 1 - Wendy',
      subTitle: 'Reclaimed Nest and The Birch Lamb Road',
      text: 'Wendy is a vendor at Everything Goes, as well as a personal friend. We\'ve been picking together for years, and always have a good time together being our quirky selves. In this podcast we reminisce about our pickings up north with our families and as always having a good laugh doing so!',
      etsy: 'https://www.etsy.com/shop/TheBirchLambRoad',
      instagram: 'https://www.instagram.com/thebirchlambroad/',
      podbean: 'https://itdoesntjustappear.podbean.com/e/fun-pickins-with-wendy/',
      download: 'https://itdoesntjustappear.podbean.com/mf/play/3pa2hc/project_wendy3.mp3',
      image: wendyPodcast
    },  
  ];

  /**
   * Podcast HTML
   * 
   * Create a card for each Podcast Episode
   */
  for (const item of episodes) {

    episodesHTML.push(

      <Row xs={1} md={2} className="">    
        <Col>
          <Card>
            <Card.Body>
              <Card.Img variant="top" src={ item.image } className="podcastImage pt-2 img-thumbnail" style={{ alignSelf: 'center' },{ maxWidth: '30rem' }} />        
            </Card.Body>
          </Card>
        </Col>    
        <Col>
          <Card>        
            <Card.Body>

              {/* Episode Name */}
              <Card.Text className="cardHeader">{ item.title }</Card.Text>
              
              {/* Interviee Name */}
              { ( item.subTitle ) ? <Card.Text className="cardHeader2">{ item.subTitle }</Card.Text> : '' }
              
              {/* About Episode */}
              <Card.Text className="cardText">{ item.text }</Card.Text>   

              {/* Social Icons */}
              <Card.Text className="cardText">

                {/* Etsy */}
                { 
                  ( item.etsy ) ? 
                  <a className="socialIcon" href={ item.etsy } target="_blank"> 
                    <FontAwesomeIcon icon={ faEtsy } /> 
                  </a> 
                  : '' 
                }
                
                {/* Instagram */}
                { 
                  ( item.instagram ) ? 
                  <a className="socialIcon" href={ item.instagram } target="_blank"> 
                    <FontAwesomeIcon icon={ faInstagram } /> 
                  </a> 
                  : '' 
                }

                {/* Podbean */}
                <a className="socialIcon" href={ item.podbean } target="_blank"> 
                  <FontAwesomeIcon icon={ faPodcast } />
                </a> 
                
                {/* Podbean Download */}
                <a className="socialIcon" href={ item.download } target="_blank" download> 
                  <FontAwesomeIcon icon={ faDownload } />
                </a> 
                
              </Card.Text>

            </Card.Body>
          </Card>
        </Col>        
      </Row>
    );
  }

  return (
    <div id="podcastDiv">      
      <Row className="pt-3">
        <Card>
          <Card.Body>
            <Card.Text className="cardTitle text-center pt-3">It Doesn't Just Appear!</Card.Text>
          </Card.Body>
        </Card>
      </Row>

      <Row xs={1} md={2} className="">        
        <Col>
          <Card>        
            <Card.Body>
              <Card.Text className="cardText">
                It Doesn't Just Appear is a podcast that takes you inside the minds and stories behind the antique/vintage treasures found by "pickers," interviewed by Nanci Cowles, a "picker" and shop owner of Everything Goes. A "picker" is the art of buying antique/vintage items from individual parties or auctions then reselling to antique shops or at vintage markets.
              </Card.Text>
              <Card.Text className="cardText">
                The stories how these treasures arrive into shops are not only amazing, but funny, crazy and unbelievable! Every piece that is found has it's own unique way it arrived to its destination!! Tune in weekly to hear the stories these "pickers" share and next time you walk into an antique/vintage shop or market you'll understand how, It Doesn't Just Appear!
              </Card.Text>
              <Card.Text className="cardText">
              If you're a "picker," and have a story to share, let me know by contacting me below!
              </Card.Text>                            
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Img variant="top" src={ itDoesntJustAppear } className="podcastImage pt-2 img-thumbnail" style={{ alignSelf: 'center' }} />        
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Output Each Episode */}
      { episodesHTML }    
    </div>
  );
}

export default Podcast;