import React from 'react';
import { Row, Col, Navbar, Container, NavDropdown, Nav } from 'react-bootstrap';

export default function Header() {

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <Container>
      {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto ms-auto">
          <Nav.Link className="me-3 ms-3" id="aboutNavLink" href="#aboutDiv">About</Nav.Link> 
          <Nav.Link className="me-3 ms-3" id="galleryNavLink" href="#galleryDiv">Gallery</Nav.Link>
          <Nav.Link className="me-3 ms-3" id="podcastNavLink" href="#podcastDiv">Podcasts</Nav.Link>                   
          <Nav.Link className="me-3 ms-3" id="contactNavLink" href="#contactDiv">Social Media</Nav.Link>          
        </Nav>       
      </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}