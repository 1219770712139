import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Container } from 'react-bootstrap';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {

  static defaultProps = {
    center: {
      lat: 33.593170,
      lng: -112.041200
    },
    zoom: 15
  };

  render() {
    return (
      <Container className="p-4">
        {/* Important! Always set the container height explicitly */}
        <div style={{ height: '250px', width: '100%' }}>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: 'AIzaSyBpBvuIwsYULrrlj0HyWPE48d-0snam-x8' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <AnyReactComponent
              lat={33.593170}
              lng={-112.041200}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </Container>        
    );
  }
}

export default Map;