
import './index.css';
import Alert from 'react-bootstrap/Alert';

function PodcastBanner() {
  return (
    <div className="podcastBanner">
      <Alert className="mb-0">
        NEW! Everything Goes Podcast, It Doesn't Just Appear! <a href="#" target="_self" className="podcastLink">[View Episodes]</a>
      </Alert>
    </div>
  );
}

export default PodcastBanner;
