// Imports
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Map from '../Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (    
    <div id="footerDiv">
      <Row className="text-left pt-3" style={{ backgroundColor: '#343a40', color: '#fff' }}>  
        <Row xs={1} md={2} className="pt-3">    
          <Col>
            <h5 className='cardHeader'>Everything Goes AZ</h5>
            <p>
              One Of A Kinds and Hard To Find antiques, vintage, shabby chic, industrial and many other styles of home decor and furniture with new items added daily!
            </p>
            <p>
              Everything Goes is a unique, and one of a kind store offering home furnishing with over 20 vendors, each with their own design style making Everything Goes a unique shopping experience!
            </p>
          </Col>
          <Col className="pt-3">
            <Map />
          </Col>
        </Row>
      </Row>
    </div>
  );
}